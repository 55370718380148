<template>
    <div style="margin-top: 50px ; margin-left: 50px;">
      <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="推送用户">
        <el-input v-model="form.alias" clearable style="width: 500px;"></el-input>
      </el-form-item>
      <!-- <el-form-item label="推送类型">
        <el-input v-model="form.extraParam" clearable style="width: 500px;"></el-input>
      </el-form-item>
      <p>video,alarm,share,system</p> -->
      <el-form-item label="推送标题">
        <el-input v-model="form.notificationTitle" clearable style="width: 500px;"></el-input>
      </el-form-item>
      <el-form-item label="消息标题">
        <el-input v-model="form.msgTitle" clearable style="width: 500px;"></el-input>
      </el-form-item>
      <el-form-item label="消息内容">
        <el-input v-model="form.msgContent" clearable style="width: 500px;"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即推送</el-button>
      <el-button @click="open">清空</el-button>                                          
    </el-form-item>         
  </el-form>
    </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'CsApp',

  data() {
    return {
      form: {
        alias: '',//手机号
        notificationTitle: '',
        msgTitle: '',
        msgContent :'',
        extraParam : 'video'
      }
    };
  },

  mounted() {
    // this.h1()
  },

  methods: {
    async onSubmit(){
      this.form.alias.replace("，",",")
      let a = this.form.msgContent
      // if(a.alias === null || a.alias === ""){
      //   open();
      //   return;
      // }
      // eslint-disable-next-line no-empty
      if(!a.endsWith("mp4")){
        this.$message.error('传入视频格式不对,请输入mp4格式的视频');
        return;
      }
      console.log(this.form);
      const res = await axios({
          method: 'POST',
          url: 'http://cf2022.lt001.top/api/pushMessage',
          data: this.form,
          headers : {'token' : "yAbiA0iCHapoxb6Sorwb"}
          });
      // this.tableData = res.data
      console.log(res);
      // eslint-disable-next-line no-empty
      this.$message('推送成功');
    },
    open() {
      this.form.alias = "";
      this.form.notificationTitle = "";
      this.form.msgTitle = "";
      this.form.msgContent = "";
      this.form.extraParam = "";
    },
  },
};
</script>

<style lang="scss" scoped>

</style>